import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import './Outdoors.css';

import skypond from "../../Assets/Outdoors/sky_pond.jpg";
import skypond2 from "../../Assets/Outdoors/sky_pond_2.jpg";
import lorch from "../../Assets/Outdoors/lorch.jpeg";
import lorch2 from "../../Assets/Outdoors/lorch_2.jpg";
import Alberta from "../../Assets/Outdoors/alberta.jpeg";
import timberfalls from "../../Assets/Outdoors/timberfalls.jpeg";
import lakeofglass from "../../Assets/Outdoors/lake_of_glass.jpg";
import lakeofglass2 from "../../Assets/Outdoors/lake_of_glass_2.jpg";
import nymphlake from "../../Assets/Outdoors/nymph_lake.jpg";
import nymphlake2 from "../../Assets/Outdoors/nymph_lake_2.jpg";
import emeraldlake from "../../Assets/Outdoors/emerald_lake.jpg";
import petyolake from "../../Assets/Outdoors/petyo_lake.jpeg";
import morainelake from "../../Assets/Outdoors/moraine_lake.jpg";

const Outdoors = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [orientations, setOrientations] = useState({}); // Track orientations for each image
  const [backgroundLoaded, setBackgroundLoaded] = useState(false); // Track background image load state

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Preload background image
    const backgroundImage = new Image();
    backgroundImage.src = '../../Assets/Outdoors/background_2.jpg'; // Path to your background image
    backgroundImage.onload = () => {
      setBackgroundLoaded(true); // Set to true once background is loaded
    };

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const adventures = [
    {
      title: "Loch Vale 🇺🇸",
      description: "Making good speed, we continued to Loch Vale. This added around an hour to our hike as it was a distance away. The pond was mostly still, with a great canvas background of what was to come. We continued around the pond, which had some mini obstacle courses (around a bridge and up and down small hills). Then we reached the foot of what seemed like an endless stairway.",
      images: [lorch,lorch2], 
    },
    {
      title: "Timberline Falls 🇺🇸",
      description: "We were close to our objective, and this was the part where it got tricky with a large ascent/elevation gain. Up the stairs we went, and we were met with Timberline Falls, which we had to traverse vertically for at least half a mile. It was partially icy at the start, but soon I realized there wasn’t much water streaming down from the falls either. (Perhaps it was because we were in the tail end of autumn.) The climb up the rock scrambles was difficult; we had to use both upper and lower body muscles at times to get up. Coupled with the fact that there were people coming down, it was a little unorganized and messy. After our 30-minute struggle on the rocks, we were greeted by the Lake of Glass with a gust of strong wind.",
      images: [timberfalls,lakeofglass,lakeofglass2],
    },
    {
      title: "Emerald Lake 🇺🇸",
      description: "This was an unexpected adventure, as we initially wanted to hike to Lake Odessa. Starting at the Bear Trailhead, it was crowded with many visitors, but there were a lot more parking spots compared to the Glacier Gorge Trailhead. The weather wasn’t really in our favor either—it was partly raining as we ascended into higher ground. We stopped midway as the rain and wind made it difficult to continue up the ridge. Then, we had the conversation to decide if we wanted to continue on. We pressed on. Soon, we reached the next viewpoint, Nymph Lake. It was smaller than Loch Lake, but it had a beautiful valley view, as it was sandwiched between two large mountains. We passed the river in no time and continued on to additional elevation that finally took us to Emerald Lake! It was cloudy, windy, and cold. We took a break at this spot and enjoyed snacks while taking some amazing pictures. Afterward, we made our way back to Nymph Lake and contemplated whether we wanted to branch off to Lake Odessa. My companions feared that it would set us back by a couple of hours, and it would be too dark when we tried to get back. Hence, we decided to just make our way back to the trailhead. Overall, this was a pretty easy hike, especially considering that we did an extremely difficult hike the day before.",
      images: [nymphlake,nymphlake2,emeraldlake],
    },
    {
      title: "Petyo Lake 🇨🇦",
      description: "Petyo Lake had a great view, but boy, was it overcrowded. Flooded with tourists from all parts of the world, we found ourselves standing in line to take pictures from a scenic spot. The hike up to the viewpoint was fairly easy, so it was easily accessible to most tourists.",
      images: [petyolake],
    },
    {
      title: "Moraine Lake 🇨🇦",
      description: "The amazing peaks of Moraine Lake were ones that will last a lifetime. We had to make advance bookings and realized that we booked it on the last day of the season that Moraine Lake was open. Lucky us.",
      images: [morainelake],
    }
  ];
  const checkOrientation = (imgSrc, index) => {
    const imgElement = new Image();
    imgElement.src = imgSrc;

    imgElement.onload = () => {
      setOrientations(prev => ({
        ...prev,
        [index]: imgElement.height > imgElement.width ? 'portrait' : 'landscape',
      }));
    };
  };

  return (
    <Container fluid className="travel-blog">
      {adventures.map((adventure, index) => {
        return (
          <Row key={index} className="adventure-row align-items-center">
            {adventure.images.length > 1 ? (
              // If more than 1 image, use Carousel
              <>
                <Col md={6} className="image-col">
                  <Carousel>
                    {adventure.images.map((image, imgIndex) => {
                      const orientation = orientations[`${index}-${imgIndex}`] || 'landscape'; // Use combined key for uniqueness
                      return (
                        <Carousel.Item key={imgIndex}>
                          <img
                            src={image}
                            alt={`${adventure.title} - ${imgIndex + 1}`}
                            className={`adventure-image ${orientation === 'portrait' ? 'portrait-image' : 'landscape-image'}`}
                            onLoad={() => checkOrientation(image, `${index}-${imgIndex}`)} // Check orientation on load
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </Col>
                <Col md={6} className="description-col">
                  <h2>{adventure.title}</h2>
                  <p>{adventure.description}</p>
                </Col>
              </>
            ) : (
              // If only 1 image, render it normally
              <>
                <Col md={6} className="image-col">
                  <img
                    src={adventure.images[0]}
                    alt={adventure.title}
                    className={`adventure-image ${orientations[`${index}-0`] === 'portrait' ? 'portrait-image' : 'landscape-image'}`}
                    onLoad={() => checkOrientation(adventure.images[0], `${index}-0`)} // Check orientation on load
                  />
                </Col>
                <Col md={6} className="description-col">
                  <h2>{adventure.title}</h2>
                  <p>{adventure.description}</p>
                </Col>
              </>
            )}
          </Row>
        );
      })}
    </Container>
  );
};

export default Outdoors;
