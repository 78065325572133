import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Mepic from "../../Assets/Mepic.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  // State for storing the visitor count
  const [visitorCount, setVisitorCount] = useState(0);

  // When the component mounts, retrieve and increment the counter from localStorage
  useEffect(() => {
    // Retrieve the current count from localStorage
    let count = parseInt(localStorage.getItem("visitorCount"), 10);

    if (!count) {
      // If there's no count in localStorage, start from 0
      count = 0;
    }

    // Increment the counter
    count += 1;

    // Store the updated count in localStorage
    localStorage.setItem("visitorCount", count);

    // Update the state with the new count
    setVisitorCount(count);
  }, []);

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Brief Introduction Of Myself
            </h1>
            <p className="home-about-body">
              I am an Applied Mathematics and Economics graduate from the National University Of Singapore
              <br />
              <br />I am fluent in classical programming languages like
              <i>
                <b className="purple"> Python, SQL, JavaScript and R </b>
              </i>
              <br />
              <br />
              My field of interest is in analyzing financial datasets and building tools for &nbsp;
              <i>
                <b className="purple">Trading</b> and
                {" "}
                <b className="purple">
                  Portfolio Analytics
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I am a tech enthusiast that is excited to pick up new coding languages/technology skills. Furthermore, I am curious about understanding new financial products/assets and mathematics. 
              Find out more about my past experiences and capabilities today!
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Mepic} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        {/* Visitor Counter Display */}
        <Row>
          <Col md={12} className="home-about-social">
            <h1>VISITOR COUNT: {visitorCount}</h1>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Enduranced"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/spencer-n-7656a897/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:spencer8848@gmail.com?subject='Website'&body=Description"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;